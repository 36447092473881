import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
  Image,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from "@wix/wix-ui-icons-common";
import SupportAvatar from "./support-avatar.png";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function App() {
  const [client, setClient] = React.useState(null as any);
  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }

    try {
      document.getElementById("root")!.style.display = "none";
      fetch(
        "https://certifiedcode.wixsite.com/custom-dashboard/_functions/decodeinstance?instance=" +
          new URLSearchParams(window.location.search).get("instance")
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (
            data?.instance?.instanceId !==
            "f85651b2-6d16-4a32-b92c-33d42f69c801"
          ) {
            showApp();
          }
        });
    } catch (error) {
      console.error(error);
      showApp();
    }

    function showApp() {
      document.getElementById("root")!.style.display = "block";
    }
  }, []);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingLayout
          size="tiny"
          title={"We are here to help"}
          description={
            "Your dedicated team from Certified Code is here to help you with any questions you may have."
          }
          actions={
            <Button
              size="small"
              skin="ai"
              suffixIcon={<Icons.AIFilledSmall />}
              as={"a"}
              href={`https://support.certifiedcode.us/?utm_source=wix`}
              target={"_blank"}
            >
              {"Chat with us"}
            </Button>
          }
          image={<Image src={SupportAvatar} width={100} transparent />}
        />
      </Card>
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
